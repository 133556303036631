<template>
  <div>
    <b-table
      :fields="fields"
      :items="items"
      :busy="isBusy"
      ref="leave-entitlement-list-dt"
      id="leave-entitlement-list-dt"
      table-class="table-head-custom table-vertical-center"
      responsive
      show-empty
      hover
      @row-clicked="showLeaveEntitlement"
    >
      <!-- A custom formatted header cell for field 'name' -->
      <template v-slot:head(index)="data">
        <span class="text-dark-75">{{ data.label.toUpperCase() }}</span>
      </template>

      <!-- A virtual column -->
      <template v-slot:cell(index)="data">
        <span class="font-weight-bolder">{{ data.index + 1 }}</span>
      </template>

      <template v-slot:cell(name)="data">
        <div class="d-flex flex-column">
          <span class="font-weight-bolder text-h6">
            {{ data.item.name }}
          </span>
        </div>
      </template>

      <template v-slot:cell(employees)="data">
        <CommonEmployeeSummary
          :item="morphItem(data.item)"
        ></CommonEmployeeSummary>
      </template>

      <template v-slot:cell(actions)="data">
        <div class="d-flex flex-row justify-content-left">
          <!-- begin::: edit-details button -->
          <b-button
            class="btn btn-icon btn-light btn-hover-primary btn-sm mr-3"
            v-on:click="showLeaveEntitlement(data.item)"
            v-b-tooltip.hover="$t('LEAVE.HOVER.NAVIGATEENT')"
          >
            <span class="svg-icon svg-icon-primary menu-icon">
              <inline-svg src="/media/svg/icons/Navigation/Right-2.svg" />
            </span>
          </b-button>
          <!-- end::: edit-details button -->
        </div>
      </template>

      <template #table-busy>
        <AppLoader #table-busy></AppLoader>
      </template>

      <template #empty>
        <AppEmptyList
          text-top="Empty Employee List"
          text-bottom=""
        ></AppEmptyList>
      </template>
    </b-table>

    <div class="d-flex justify-content-end" v-if="items.length">
      <b-pagination
        v-model="pagination.current_page"
        :total-rows="pagination.total"
        :per-page="pagination.per_page"
        aria-controls="leave-entitlement-list-dt"
        @change="handlePageChange"
        size="lg"
      ></b-pagination>
    </div>
    <LeavePageEntitlementModal></LeavePageEntitlementModal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AppLoader from "@/modules/core/components/loaders/AppLoader";
import LeavePageEntitlementModal from "@/modules/company/components/leave/entitlement/employee/LeaveEmployeeEntitlementPageModal";
import AppEmptyList from "@/modules/core/components/empty/AppEmptyList";
import CommonEmployeeSummary from "@/modules/core/components/company/CommonEmployeeSummary";

export default {
  name: "LeavePageEntitlementList",
  props: ["reload"],
  components: {
    CommonEmployeeSummary,
    AppEmptyList,
    LeavePageEntitlementModal,
    AppLoader,
  },
  mounted() {
    this.fetch();
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      fields: [
        { key: "index", label: "#" },
        { key: "employees", label: this.$t("EMPLOYEE.HEADER") },
        { key: "group", label: this.$t("EMPLOYEE.GROUP.BASE") },
        { key: "actions", label: this.$t("DETAILSTABLE.ACT") },
      ],
      isBusy: true,
    };
  },
  methods: {
    fetch() {
      this.isBusy = true;
      this.$store
        .dispatch("fetchEmployeeListForLeave")
        .finally(() => (this.isBusy = false));
    },

    handlePageChange(value) {
      console.log(value);
      this.$store.commit("setEmployeeListForLeavePage", value);
      this.fetch();
    },

    showLeaveEntitlement(item) {
      console.log("Selected Leave ", item);
      this.$store.dispatch("assignSelectedEntitlementForEmployee", {
        id: item.id,
        name: `${item.first_name} ${item.last_name}`,
      });
      this.$router.push({
        name: "LeavePageEntitlementTable",
        query: { id: item.id, name: `${item.first_name} ${item.last_name}` },
      });
    },

    morphItem(item) {
      return {
        name: item.full_name,
        code: item.unique_code,
        email: item.email,
        image_url: item.image_url,
      };
    },

    onRowSelected(items) {
      console.log("Selected item", items);
      if (items.length) {
        const employee = items[0];
        console.log("Selected single item", items[0]);
        this.showLeaveEntitlement(employee);
      }
    },
  },

  computed: {
    ...mapGetters({
      items: "getCompanyLeaveEntitlementList",
      pagination: "getCompanyLeaveEntitlementListPagination",
    }),
  },
  watch: {
    reload() {
      console.log("Reload Table!");
      this.fetch();
    },
  },
};
</script>

<style scoped></style>
