<template>
  <div>
    <LeaveEmployeeTableControl class="mb-5"></LeaveEmployeeTableControl>
    <AppPageCard>
      <template #card-title>
        {{ $t("LEAVE.ENTITLEMENT") }}
      </template>

      <template #card-toolbar>
        <b-button
          class="btn btn-icon btn-light btn-hover-primary mr-3"
          v-on:click="refresh"
          v-b-tooltip.hover="$t('EMPLOYEE.HOVER.REFRESH')"
        >
          <span class="svg-icon svg-icon-2x svg-icon-primary">
            <inline-svg src="/media/svg/icons/Media/Repeat.svg" />
          </span>
        </b-button>
      </template>

      <b-alert
        variant="info"
        show="5"
        fade
        dismissible
        v-if="formData.length > 0"
      >
        {{ formData }}
      </b-alert>

      <LeavePageEntitlementList :reload="reload"></LeavePageEntitlementList>
    </AppPageCard>
  </div>
</template>

<script>
import LeavePageEntitlementList from "@/modules/company/components/leave/entitlement/LeaveEntitlementPageTable";
import AppPageCard from "@/modules/core/components/shared/card/AppPageCard";
import LeaveEmployeeTableControl from "@/modules/company/components/leave/control/LeaveEmployeeTableControl";

export default {
  name: "LeavePageEntitlement",
  components: {
    LeaveEmployeeTableControl,
    AppPageCard,
    LeavePageEntitlementList,
  },
  mounted() {},
  data() {
    return {
      formData: "",
      reload: false,
    };
  },
  methods: {
    refresh() {
      this.reload = !this.reload;
    },
  },
};
</script>

<style scoped></style>
